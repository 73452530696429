const ModuleConfig = {

  primaryKey: "id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
    name: {
      label: "Name",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    email: {
      label: "Email",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    phone_no: {
      label: "Phone",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    password: {
      label: "Password",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    role: {
      label: "Role",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Role",
      options: [
        {
          label: "Admin",
          value: "Admin",
        },
        {
          label: "User",
          value: "User",
        },
      ],
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
