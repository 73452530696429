const ModuleConfig = {
  primaryKey: 'id',

  formDefaultValue: {
    title: "",
    detail: "",
    description: "",
    status : "",
    publish_time : "",

    options: [
      { options: "", is_answer: "" },
      { options: "", is_answer: "" },
      { options: "", is_answer: "" },
      { options: "", is_answer: "" },
    ]
  },

  formFields: {

    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },
    detail: {
      label: "Detail",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },
    description: {
      label: "Description",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },
    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-4",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Deactive",
          value: "Deactive",
        },
      ],
    },
    publish_date: {
      label: "Publish Date",
      isRequired: true,
      colWidth: "col-md-4",
      type: "date",
    },

    options: {
      label: "Options",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "array",
      type: "array",
      canAddMore: false,
      fields: [
        {
          options: {
            name: "question_title",
            hideLabel: true,
            label: "Question Title",
            isRequired: true,
            colWidth: "col-md-8",
            inputType: "text",
            type: "text",
          },
          is_answer: {
            name: "answer",
            label: "Correct answer",
            isRequired: true,
            colWidth: "col-md-4",
            inputType: "checkbox",
            type: "checkbox",
          },
        },
      ],
    },
  },

  addMoreFields: {
    question_title: "",
    options: [
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
    ],
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
