import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { PushNotificationDetailInterface } from "./models/PushNotificationDetailInterface";
import {
  createPushNotification,
  deletePushNotification,
  getPushNotificationDetail,
  getPushNotificationList,
  updatePushNotification,
} from "./PushNotificationAPI";

export interface PushNotificationStateInterface {
  pushnotifications: PushNotificationDetailInterface[];
  favourites: PushNotificationDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: PushNotificationDetailInterface;
}

const initialState: PushNotificationStateInterface = {
  pushnotifications: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    title: "",
    description: "",
  },
};

export const getPushNotificationListAsync = createAsyncThunk(
  "pushnotification/list",
  async (data: any) => {
    const response = await getPushNotificationList(data);
    return response.data;
  }
);
export const getPushNotificationDetailAsync = createAsyncThunk(
  "pushnotification/detail",
  async (index: string) => {
    const response = await getPushNotificationDetail(index);
    return response.data;
  }
);

export const createPushNotificationAsync = createAsyncThunk(
  "pushnotification/create",
  async (data: PushNotificationDetailInterface) => {
    const response = await createPushNotification(data);
    return response.data;
  }
);

export const deletePushNotificationAsync = createAsyncThunk(
  "pushnotification/delete",
  async (index: string) => {
    const response = await deletePushNotification(index);
    return response.data;
  }
);

export const updatePushNotificationAsync = createAsyncThunk(
  "pushnotification/update",
  async (data: { index: string; data: PushNotificationDetailInterface }) => {
    const response = await updatePushNotification(data.index, data.data);
    return response.data;
  }
);

export const pushnotificationSlice = createSlice({
  name: "pushnotification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getPushNotificationListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPushNotificationListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.pushnotifications = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getPushNotificationListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getPushNotificationDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPushNotificationDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getPushNotificationDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createPushNotificationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPushNotificationAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createPushNotificationAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updatePushNotificationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePushNotificationAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updatePushNotificationAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deletePushNotificationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePushNotificationAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deletePushNotificationAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = pushnotificationSlice.actions;
export const PushNotificationState = (state: RootState) => state.pushnotification;
export default pushnotificationSlice.reducer;
