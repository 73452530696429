import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { TopicDetailInterface } from "./models/TopicDetailInterface";
import {
  createTopic,
  deleteTopic,
  getTopicDetail,
  getTopicList,
  updateTopic,
} from "./TopicAPI";

export interface TopicStateInterface {
  topics: TopicDetailInterface[];
  favourites: TopicDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: TopicDetailInterface;
}

const initialState: TopicStateInterface = {
  topics: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    title: "",
    description: "",
  },
};

export const getTopicListAsync = createAsyncThunk(
  "topic/list",
  async (data: any) => {
    const response = await getTopicList(data);
    return response.data;
  }
);
export const getTopicDetailAsync = createAsyncThunk(
  "topic/detail",
  async (index: string) => {
    const response = await getTopicDetail(index);
    return response.data;
  }
);

export const createTopicAsync = createAsyncThunk(
  "topic/create",
  async (data: TopicDetailInterface) => {
    const response = await createTopic(data);
    return response.data;
  }
);

export const deleteTopicAsync = createAsyncThunk(
  "topic/delete",
  async (index: string) => {
    const response = await deleteTopic(index);
    return response.data;
  }
);

export const updateTopicAsync = createAsyncThunk(
  "topic/update",
  async (data: { index: string; data: TopicDetailInterface }) => {
    const response = await updateTopic(data.index, data.data);
    return response.data;
  }
);

export const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getTopicListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopicListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.topics = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getTopicListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getTopicDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopicDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getTopicDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createTopicAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTopicAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createTopicAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateTopicAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTopicAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateTopicAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteTopicAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTopicAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteTopicAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = topicSlice.actions;
export const TopicState = (state: RootState) => state.topic;
export default topicSlice.reducer;
