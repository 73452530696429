const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    id: "",
    title: "",
    description: "",
  },

  formFields: {
    dept_id: {
      label: "Select Department",
      isRequired: false,
      colWidth: "col-md-4",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/departments",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Department",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },

    title: {
      label: "Notification title",
      isRequired: false,
      colWidth: "col-md-12",
      inputType: "text",
      type: "text",
    },

    description: {
      label: "Notification description",
      isRequired: false,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },

    // time: {
    //   label: "Notification time",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   type: "time",
    // },

    // status: {
    //   label: "Status",
    //   isRequired: false,
    //   colWidth: "col-md-6",
    //   inputType: "select",
    //   type: "select",
    //   placeholder: "Select Status",
    //   options: [
    //     {
    //       label: "Active",
    //       value: "Active",
    //     },
    //     {
    //       label: "Inactive",
    //       value: "Inactive",
    //     },
    //   ],
    // },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
