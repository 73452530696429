import { Route } from "react-router-dom";
import FAQCreate from "./pages/FAQCreate";
import FAQList from "./pages/FAQList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "faq";
const FAQRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<FAQList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<FAQCreate />} key={`${MODULE_ROUTE}_create`} />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<FAQCreate />} key={`${MODULE_ROUTE}_edit`} />,
];

export default FAQRoute;