import AxiosInstance from "../../utils/AxiosInstance";
import { FAQInterface } from "./models/FAQInterface";

const API_ROUTE = "faq";

export const getFAQList = async (params: any) => {
    const prm = new URLSearchParams(params).toString()
    return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getFAQDetail = async (index: string) => {
    return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createFAQ = async (data: FAQInterface) => {
    const formData = new FormData();
    const keys = Object.keys(data);
    for (var i = 0; i < keys.length; i++) {
        formData.append(keys[i], data[keys[i]]);
    }

    return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const updateFAQ = async (index: string, data: FAQInterface) => {
    // const formData = new FormData();
    // const keys = Object.keys(data);
    // for (var i = 0; i < keys.length; i++) {
    //     formData.append(keys[i], data[keys[i]]);
    // }

    return await AxiosInstance.post(`/${API_ROUTE}/${index}`, data);
};

export const deleteFAQ = async (index: string) => {
    return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};