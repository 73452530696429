import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { CourseDetailInterface } from "./models/CourseDetailInterface";
import {
  createCourse,
  deleteCourse,
  getCourseDetail,
  getCourseList,
  updateCourse,
} from "./CourseAPI";

export interface CourseStateInterface {
  courses: CourseDetailInterface[];
  favourites: CourseDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: CourseDetailInterface;
}

const initialState: CourseStateInterface = {
  courses: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
  },
};

export const getCourseListAsync = createAsyncThunk(
  "course/list",
  async (data: any) => {
    const response = await getCourseList(data);
    return response.data;
  }
);
export const getCourseDetailAsync = createAsyncThunk(
  "course/detail",
  async (index: string) => {
    const response = await getCourseDetail(index);
    return response.data;
  }
);

export const createCourseAsync = createAsyncThunk(
  "course/create",
  async (data: CourseDetailInterface) => {
    const response = await createCourse(data);
    return response.data;
  }
);

export const deleteCourseAsync = createAsyncThunk(
  "course/delete",
  async (index: string) => {
    const response = await deleteCourse(index);
    return response.data;
  }
);

export const updateCourseAsync = createAsyncThunk(
  "course/update",
  async (data: { index: string; data: CourseDetailInterface }) => {
    const response = await updateCourse(data.index, data.data);
    return response.data;
  }
);

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getCourseListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCourseListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.courses = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getCourseListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getCourseDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCourseDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getCourseDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCourseAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createCourseAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCourseAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateCourseAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCourseAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteCourseAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = courseSlice.actions;
export const CourseState = (state: RootState) => state.course;
export default courseSlice.reducer;
