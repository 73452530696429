import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getSubscriptionListAsync, SubscriptionState } from "../SubscriptionSlice";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { SubscriptionDetailInterface } from "../models/SubscriptionDetailInterface";
import ModuleConfig from "./../config/ModuleConfig";
import CustomPagination from "../../../utils/CustomPagination";
import { deleteSubscription } from "../SubscriptionAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

/**
 * Subscriptioning list page
 */
const SubscriptionList = () => {
  const { subscriptions, status, totalRecord } = useAppSelector(SubscriptionState);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const [deleteItem, setDeleteItem] = useState("");

  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getSubscriptionListAsync(filter));
  };

  const removeSubscription = async () => {
    const res = await deleteSubscription(deleteItem);
    setDeleteItem("");
    getPageData();
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "user_id",
      text: "User Id",
      sort: true,
    },
    {
      dataField: "user_id",
      text: "User Name",
      formatter: (cellContent: any, row: any) => (
        row?.user[0]?.name
      )
    },
    {
      dataField: "course_id",
      text: "Course",
      formatter: (cellContent: any, row: any) => (
        row?.course_details[0]?.title
      )
    },
    {
      dataField: "payment_id",
      text: "Payment Id",
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: 'center',
      align : "center",
      classes : "col-1",
      formatter: (cellContent: any, row: any) =>
        row.status == "Completed" ? (
          <span className="label label-success rounded-pill"> Completed </span>
        ) : row.status == "Pending" ? (
          <span className="label label-warning rounded-pill"> Pending </span>
        ) : (
          <span className="label label-danger rounded-pill"> Failed </span>
        )
    },
   
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Subscription</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Subscriptions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Subscriptions</h5>
                    </Col>
                    <Col md={2}>
                      {/* <Link className="btn btn-primary" to="/subscription/create">
                        Add Subscription
                      </Link> */}
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={subscriptions}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />
                              <ExportCSVButton
                                {...props.csvProps}
                                className="btn-success fw-bold ms-2"
                              >
                                Export CSV
                              </ExportCSVButton>
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>

                      {/* <CustomPagination
                        curretnPage={page}
                        total={totalRecord}
                        limit={10}
                        setPage={setPage}
                      /> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
export default SubscriptionList;
