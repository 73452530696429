import { Route } from "react-router-dom";
import OldQuestionCreate from "./pages/OldQuestionCreate";
import OldQuestionList from "./pages/OldQuestionList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "oldquestion"; 
const OldQuestionRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<OldQuestionList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<OldQuestionCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<OldQuestionCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default OldQuestionRoute;