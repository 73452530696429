import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import ToolkitProvider, {
    Search,
    CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FAQState, getFAQListAsync } from "../FAQSlice";
import { deleteFAQ } from "../FAQAPI";
import filterFactory from "react-bootstrap-table2-filter";

const FAQList = () => {

    const { faqs, status, totalRecord } = useAppSelector(FAQState);
    const [page, setPage] = useState(1);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { SearchBar, ClearSearchButton } = Search;
    const { ExportCSVButton } = CSVExport;

    const [deleteItem, setDeleteItem] = useState("");

    useEffect(() => {
        getPageData();
    }, [dispatch, page]);

    const getPageData = () => {
        const filter = {
            page: page,
        };
        dispatch(getFAQListAsync(filter));
    };

    const removeFAQ = async () => {
        const res = await deleteFAQ(deleteItem);
        setDeleteItem("");
        getPageData();
    };

    const customTotal = (from: any, to: any, size: any) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const options = {
        sizePerPage: 10,
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: totalRecord,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "course",
            text: "Department",
            formatter: (cellContent: any, row: any) => (
                row?.course == "1" ? "PCE" : "NPTE"
            )
        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
        },
        {
            dataField: "description",
            text: "Description",
        },
        {
            dataField: "actions",
            text: "Actions",
            headerAlign: 'center',
            align: "center",
            classes: "col-2",
            formatter: (cellContent: any, row: any) => (
                <div className="d-flex justify-content-around">
                    <i
                        className="feather icon-trash-2 text-danger fw-bold"
                        onClick={() => {
                            setDeleteItem(row.id);
                        }}
                    ></i>
                    <i
                        className="feather icon-edit text-success fw-bold"
                        onClick={() => {
                            navigate(`/faq/update/${row.id}`);
                        }}
                    ></i>
                </div>
            ),
        },
    ]

    return (
        <>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Faq</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="feather icon-home"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <a>Faqs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-body">
                <div className="page-wrapper">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <Row>
                                        <Col auto>
                                            <h5>Faqs</h5>
                                        </Col>
                                        <Col md={2}>
                                            <Link className="btn btn-primary" to="/faq/create">
                                                Add FAQ
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-block table-border-style">
                                    {status === "loading" ? (
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : status === "failed" ? (
                                        <Alert key={"danger"} variant={"danger"}>
                                            Somthing went wrong please try again
                                        </Alert>
                                    ) : (
                                        <>
                                            <ToolkitProvider
                                                keyField="id"
                                                data={faqs}
                                                columns={columns}
                                                search
                                                exportCSV
                                            >
                                                {(props: any) => (
                                                    <Row>
                                                        <div>
                                                            <SearchBar {...props.searchProps} />
                                                            <ClearSearchButton
                                                                {...props.searchProps}
                                                                className="btn-primary fw-bold ms-2"
                                                            />
                                                            <ExportCSVButton
                                                                {...props.csvProps}
                                                                className="btn-success fw-bold ms-2"
                                                            >
                                                                Export CSV
                                                            </ExportCSVButton>
                                                        </div>
                                                        <BootstrapTable
                                                            filter={filterFactory()}
                                                            pagination={paginationFactory(options)}
                                                            // selectRow={selectRow}
                                                            {...props.baseProps}
                                                        />
                                                    </Row>
                                                )}
                                            </ToolkitProvider>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteItem ? (
                <Modal show={true} onHide={() => setDeleteItem("")}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete FAQ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDeleteItem("")}>
                            Close
                        </Button>
                        <Button
                            className="btn-danger"
                            variant="primary"
                            onClick={removeFAQ}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </>
    )
}

export default FAQList