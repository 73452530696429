import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import userReducer from "../modules/user/UserSlice";

import subscriptionReducer from "../modules/subscription/SubscriptionSlice";
import courseReducer from "../modules/course/CourseSlice";
import topicReducer from "../modules/topic/TopicSlice";
import categoryReducer from "../modules/category/CategorySlice";
import questionReducer from "../modules/question/QuestionSlice";
import pushnotificationReducer from "../modules/pushnotification/PushNotificationSlice";
import myprofileReducer from "../modules/myprofile/MyProfileSlice";
import departmentReducer from "../modules/department/DepartmentSlice";
import settingReducer from "../modules/setting/SettingSlice";
import oldquestionReducer from "../modules/oldquestion/OldQuestionSlice";
import contactusReducer from "../modules/contactus/ContactUsSlice";
import faqReducer from "../modules/faq/FAQSlice"
import whyusReducer from "../modules/whyus/WhyusSlice"
/* import store */
export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    subscription: subscriptionReducer,
    course: courseReducer,
    topic: topicReducer,
    category: categoryReducer,
    question: questionReducer,
    pushnotification: pushnotificationReducer,
    myprofile: myprofileReducer,
    department: departmentReducer,
    setting: settingReducer,
    oldquestion: oldquestionReducer,
    contactus: contactusReducer,
    faq: faqReducer,
    whyus: whyusReducer
    // {addStore}
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
