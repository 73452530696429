import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { SettingDetailInterface } from "./models/SettingDetailInterface";
import {
  createSetting,
  deleteSetting,
  getSettingDetail,
  getSettingList,
  updateSetting,
} from "./SettingAPI";

export interface SettingStateInterface {
  settings: SettingDetailInterface[];
  favourites: SettingDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: SettingDetailInterface;
}

const initialState: SettingStateInterface = {
  settings: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    dept_id:"",
    exam_date:"",
    offer_pdf:"",
    about_us_pdf:"",
    live_lecture_pdf:"",
    strategy_pdf:"",
    success_story_pdf:"",
    offer: {
      offer_title:"",
      offer_link:""
    },
  },
};

export const getSettingListAsync = createAsyncThunk(
  "setting/list",
  async (data: any) => {
    const response = await getSettingList(data);
    return response.data;
  }
);
export const getSettingDetailAsync = createAsyncThunk(
  "setting/detail",
  async (index: string) => {
    const response = await getSettingDetail(index);
    return response.data;
  }
);

export const createSettingAsync = createAsyncThunk(
  "setting/create",
  async (data: SettingDetailInterface) => {
    const response = await createSetting(data);
    return response.data;
  }
);

export const deleteSettingAsync = createAsyncThunk(
  "setting/delete",
  async (index: string) => {
    const response = await deleteSetting(index);
    return response.data;
  }
);

export const updateSettingAsync = createAsyncThunk(
  "setting/update",
  async (data: { index: string; data: SettingDetailInterface }) => {
    const response = await updateSetting(data.index, data.data);
    return response.data;
  }
);

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getSettingListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSettingListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getSettingListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getSettingDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSettingDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getSettingDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createSettingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSettingAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createSettingAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateSettingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSettingAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateSettingAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteSettingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSettingAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteSettingAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = settingSlice.actions;
export const SettingState = (state: RootState) => state.setting;
export default settingSlice.reducer;
