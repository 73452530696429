import { Route } from "react-router-dom";
import PushNotificationCreate from "./pages/PushNotificationCreate";
import PushNotificationList from "./pages/PushNotificationList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "pushnotification"; 
const PushNotificationRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<PushNotificationList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<PushNotificationCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<PushNotificationCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default PushNotificationRoute;