import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WhyusInterface } from "./models/WhyusInterface";
import { createWhyus, deleteWhyus, getWhyusDetail, getWhyusList, updateWhyus } from "./WhyusAPI";
import { RootState } from "../../stores";

export interface WhyusStateInterface {
    whyus: WhyusInterface[];
    favourites: WhyusInterface[];
    totalRecord: number;
    status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
    detail: WhyusInterface;
}

const initialState: WhyusStateInterface = {
    whyus: [],
    favourites: [],
    totalRecord: 0,
    status: "idle",
    detail: {
        id: "",
        title: "",
        description: "",
    },
};

export const getWhyusListAsync = createAsyncThunk(
    "whyus/list",
    async (data: any) => {
        const response = await getWhyusList(data);
        return response.data;
    }
);

export const getWhyusDetailAsync = createAsyncThunk(
    "whyus/detail",
    async (index: string) => {
        const response = await getWhyusDetail(index);
        return response.data;
    }
);

export const createWhyusAsync = createAsyncThunk(
    "whyus/create",
    async (data: WhyusInterface) => {
        const response = await createWhyus(data);
        return response.data;
    }
);

export const deleteWhyusAsync = createAsyncThunk(
    "whyus/delete",
    async (index: string) => {
        const response = await deleteWhyus(index);
        return response.data;
    }
);

export const updateWhyusAsync = createAsyncThunk(
    "whyus/update",
    async (data: { index: string; data: WhyusInterface }) => {
        const response = await updateWhyus(data.index, data.data);
        return response.data;
    }
);

export const whyusSlice = createSlice({
    name: "whyus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // GET LIST
            .addCase(getWhyusListAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getWhyusListAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.whyus = action.payload.data;
                state.totalRecord = action.payload.total;
            })
            .addCase(getWhyusListAsync.rejected, (state) => {
                state.status = "failed";
            })
            // GET DETAIL
            .addCase(getWhyusDetailAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getWhyusDetailAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.detail = action.payload;
            })
            .addCase(getWhyusDetailAsync.rejected, (state) => {
                state.status = "failed";
            })
            // CREATE
            .addCase(createWhyusAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createWhyusAsync.fulfilled, (state, action) => {
                state.status = "created";
                state.detail = action.payload;
            })
            .addCase(createWhyusAsync.rejected, (state) => {
                state.status = "failed";
            })
            // UPDATE
            .addCase(updateWhyusAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateWhyusAsync.fulfilled, (state, action) => {
                state.status = "updated";
                state.detail = action.payload;
            })
            .addCase(updateWhyusAsync.rejected, (state) => {
                state.status = "failed";
            })
            // DELETE
            .addCase(deleteWhyusAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteWhyusAsync.fulfilled, (state, action) => {
                state.status = "deleted";
                state.detail = action.payload;
            })
            .addCase(deleteWhyusAsync.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export const { } = whyusSlice.actions;
export const WhyusState = (state: RootState) => state.whyus;
export default whyusSlice.reducer;