import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { ContactUsDetailInterface } from "./models/ContactUsDetailInterface";
import {
  createContactUs,
  deleteContactUs,
  getContactUsDetail,
  getContactUsList,
  updateContactUs,
} from "./ContactUsAPI";

export interface ContactUsStateInterface {
  contactuss: ContactUsDetailInterface[];
  favourites: ContactUsDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: ContactUsDetailInterface;
}

const initialState: ContactUsStateInterface = {
  contactuss: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
};

export const getContactUsListAsync = createAsyncThunk(
  "contactus/list",
  async (data: any) => {
    const response = await getContactUsList(data);
    return response.data;
  }
);
export const getContactUsDetailAsync = createAsyncThunk(
  "contactus/detail",
  async (index: string) => {
    const response = await getContactUsDetail(index);
    return response.data;
  }
);

export const createContactUsAsync = createAsyncThunk(
  "contactus/create",
  async (data: ContactUsDetailInterface) => {
    const response = await createContactUs(data);
    return response.data;
  }
);

export const deleteContactUsAsync = createAsyncThunk(
  "contactus/delete",
  async (index: string) => {
    const response = await deleteContactUs(index);
    return response.data;
  }
);

export const updateContactUsAsync = createAsyncThunk(
  "contactus/update",
  async (data: { index: string; data: ContactUsDetailInterface }) => {
    const response = await updateContactUs(data.index, data.data);
    return response.data;
  }
);

export const contactusSlice = createSlice({
  name: "contactus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getContactUsListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContactUsListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.contactuss = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getContactUsListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getContactUsDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContactUsDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getContactUsDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createContactUsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createContactUsAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createContactUsAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateContactUsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContactUsAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateContactUsAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteContactUsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContactUsAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteContactUsAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = contactusSlice.actions;
export const ContactUsState = (state: RootState) => state.contactus;
export default contactusSlice.reducer;
