import { useEffect } from "react";
import { Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  appState,
  getDashboardAsync,
} from "../stores/appSlice";
import { useAppDispatch, useAppSelector } from "../stores/hooks";

const Dashboard = () => {
  const { status, dashboard } = useAppSelector(appState);
  const dispatchApp = useAppDispatch();

  useEffect(() => {
    dispatchApp(getDashboardAsync());
  }, []);

  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  return (
    <div className="pcoded-wrapper">
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              {status === "loading" ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : status === "failed" ? (
                <Alert key={"danger"} variant={"danger"}>
                  Somthing went wrong please try again
                </Alert>
              ) : (
                <>
                  {/* [ Main Content ] start  */}
                  <div className="row">
                    {/*[ daily sales section ] start */}
                    <div className="col-md-6 col-xl-4">
                      <Link to="/user" className="text-decoration-none">
                        <div className="card daily-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Register User</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {dashboard.totalUser}
                                </h3>
                              </div>

                              {/* <div className="col-3 text-right">
                          <p className="m-b-0">67%</p>
                        </div> */}
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ daily sales section ] end */}


                    {/*[ year  sales section ] starts */}
                    <div className="col-md-12 col-xl-4">
                      <Link to="/question" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Total Questions</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {dashboard.totalQuestion}
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                {/* <p className="m-b-0">80%</p> */}
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "1000%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ year  sales section ] end */}

                    {/*[ year  sales section ] starts */}
                    <div className="col-md-12 col-xl-4">
                      <Link to="/subscription" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Total Subscriptions </h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {dashboard?.totalSubscription}
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                {/* <p className="m-b-0">80%</p> */}
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ year  sales section ] end */}

                    {/*[ year  sales section ] starts */}
                    <div className="col-md-12 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">
                            This Month Total Answers
                            </h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {dashboard?.CurrentMonthAnswers?.totalAnswers}
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                {/* <p className="m-b-0 text-muted">80%</p> */}
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ year  sales section ] end */}

                    {/*[ year  sales section ] starts */}
                    <div className="col-md-12 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">This Month Correct Answers</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {
                                    dashboard?.CurrentMonthAnswers
                                      ?.totalTrueAnswer
                                  }
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                <p className="m-b-0 text-muted">
                                  {
                                    dashboard?.CurrentMonthAnswers
                                      ?.truePercentage
                                  }
                                  %
                                </p>
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{
                                  width: `${dashboard?.CurrentMonthAnswers?.truePercentage}%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ year  sales section ] end */}

                    {/*[ year  sales section ] starts */}
                    <div className="col-md-12 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">This Month Incorrect Answers</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-down text-c-red f-30 m-r-10"></i>
                                  {
                                    dashboard?.CurrentMonthAnswers?.totalFalseAnswer
                                  }
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                <p className="m-b-0 text-muted">
                                  {
                                    dashboard?.CurrentMonthAnswers?.falsePercentage
                                  }
                                  %
                                </p>
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme2"
                                role="progressbar"
                                style={{
                                  width: `${dashboard?.CurrentMonthAnswers?.falsePercentage}%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ year  sales section ] end */}

                    {/*[ Recent Orders ] start */}
                    <div className="col-xl-12 col-md-12">
                      <div className="card Recent-Users">
                        <div className="card-header">
                          <h5>Recent Users</h5>
                        </div>
                        <div className="card-block px-0 py-3">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <tbody>
                                {dashboard?.recentUsers?.map(
                                  (user: any, index: any) => {
                                    return (
                                      <tr className="unread">
                                        <td>
                                          <img
                                            className="rounded-circle"
                                            style={{ width: "40px" }}
                                            src="assets/images/user/avatar-1.jpg"
                                            alt="activity-user"
                                          />
                                        </td>
                                        <td>
                                          <h6 className="mb-1">{user.name}</h6>
                                        </td>
                                        <td>
                                          <h6 className="mb-1">{user.email}</h6>
                                        </td>
                                        <td>
                                          <h6 className="text-muted">
                                            <i className="fas fa-circle text-c-green f-10 m-r-15"></i>
                                            {formatter
                                              .format(
                                                Date.parse(user.created_at)
                                              )
                                              .toUpperCase()}
                                          </h6>
                                        </td>
                                        <td>
                                          {user.status === "Inactive" ? (
                                            <a
                                              href="#!"
                                              className="label theme-bg2 text-white f-12"
                                            >
                                              Inactive
                                            </a>
                                          ) : (
                                            <a
                                              href="#!"
                                              className="label theme-bg text-white f-12"
                                            >
                                              Active
                                            </a>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*[ Recent Users ] end */}
                  </div>
                  {/* [ Main Content ] end  */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
