const Config = {
  // BASE_URL : "http://127.0.0.1:8000/",
  // API_URL : "http://127.0.0.1:8000/api/admin/",

  // BASE_URL : "http://192.168.1.13:8000/",
  // API_URL : "http://192.168.1.13:8000/api/admin/",
  
  BASE_URL: "https://dev.studybuddynpte.com/public/",
  API_URL: "https://dev.studybuddynpte.com/api/admin/",
};
export default Config;
