const DatePicker = (props: any) => {

    return (
        <input 
            type={"date"}
            name={props.name}
            id={props.name}
            value={props.values[props.name]}
            className="form-control"
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            placeholder={props.fieldDetails.label} />
    )
}

export default DatePicker;