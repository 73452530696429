import { Route } from "react-router-dom";
import SettingCreate from "./pages/SettingCreate";
import SettingList from "./pages/SettingList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "setting"; 
const SettingRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<SettingList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<SettingCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<SettingCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default SettingRoute;