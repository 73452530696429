import AxiosInstance from "../../utils/AxiosInstance";
import { SettingDetailInterface } from "./models/SettingDetailInterface";

const API_ROUTE = "settings";
export const getSettingList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getSettingDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createSetting = async (data: SettingDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] == 'offer'){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteSetting = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateSetting = async (index: string, data: SettingDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] == 'offer'){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData);
};
