import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { DepartmentDetailInterface } from "./models/DepartmentDetailInterface";
import {
  createDepartment,
  deleteDepartment,
  getDepartmentDetail,
  getDepartmentList,
  updateDepartment,
} from "./DepartmentAPI";

export interface DepartmentStateInterface {
  departments: DepartmentDetailInterface[];
  favourites: DepartmentDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: DepartmentDetailInterface;
}

const initialState: DepartmentStateInterface = {
  departments: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    status: "",
  },
};

export const getDepartmentListAsync = createAsyncThunk(
  "department/list",
  async (data: any) => {
    const response = await getDepartmentList(data);
    return response.data;
  }
);
export const getDepartmentDetailAsync = createAsyncThunk(
  "department/detail",
  async (index: string) => {
    const response = await getDepartmentDetail(index);
    return response.data;
  }
);

export const createDepartmentAsync = createAsyncThunk(
  "department/create",
  async (data: DepartmentDetailInterface) => {
    const response = await createDepartment(data);
    return response.data;
  }
);

export const deleteDepartmentAsync = createAsyncThunk(
  "department/delete",
  async (index: string) => {
    const response = await deleteDepartment(index);
    return response.data;
  }
);

export const updateDepartmentAsync = createAsyncThunk(
  "department/update",
  async (data: { index: string; data: DepartmentDetailInterface }) => {
    const response = await updateDepartment(data.index, data.data);
    return response.data;
  }
);

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getDepartmentListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartmentListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.departments = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getDepartmentListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getDepartmentDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartmentDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getDepartmentDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createDepartmentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDepartmentAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createDepartmentAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateDepartmentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDepartmentAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateDepartmentAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteDepartmentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDepartmentAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteDepartmentAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = departmentSlice.actions;
export const DepartmentState = (state: RootState) => state.department;
export default departmentSlice.reducer;
