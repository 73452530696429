const ModuleConfig = {

  primaryKey: "id",

  formDefaultValue: {
    id: "",
    name: "",
    title: "",
    description: ""
  },

  formFields: {
    course: {
      label: "Select Department",
      isRequired: true,
      colWidth: "col-md-6",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/departments",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Department",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },
    title: {
      label: "Title",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    description: {
      label: "Description",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "textarea",
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
