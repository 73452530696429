const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    course_id: "",
    category_id: "",
    question_text: "",
    question_detail: "",
    status: "",
    publish_time: "",
    publish_date: "",
    questions: [
      {
        question_title: "",
        options: [
          { option_title: "", answer: false },
          { option_title: "", answer: false },
          { option_title: "", answer: false },
          { option_title: "", answer: false },
        ],
      },
    ],
  },

  formFields: {
    // course_id: {
    //   label: "Select course",
    //   isRequired: true,
    //   colWidth: "col-md-4",
    //   type: "select",
    //   inputType: "select",
    //   asynchLoad: true,
    //   dataUrl: "/courses",
    //   keyValue: "id",
    //   keylabel: "title",
    //   placeholder: "Select Course",
    //   options: [
    //     {
    //       label: "",
    //       value: "",
    //     },
    //   ],
    // },

    dept_id: {
      label: "Select Department",
      isRequired: true,
      colWidth: "col-md-4",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/departments",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Department",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },

    category_id: {
      label: "Select Category",
      isRequired: true,
      colWidth: "col-md-4",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/categorys",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Category",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },

    question_pdf: {
      label: "Image",
      isRequired: false,
      colWidth: "col-md-4",
      inputType: "file",
      type: "file",
    },

    question_text: {
      label: "Description",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },

    question_detail: {
      label: "Detail",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-4",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
      ],
    },
    publish_date: {
      label: "Publish Date",
      isRequired: true,
      colWidth: "col-md-4",
      type: "date",
    },
    publish_time: {
      label: "Publish Time",
      isRequired: true,
      colWidth: "col-md-4",
      type: "time",
    },

    questions: {
      label: "Questions",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "array",
      type: "array",
      canAddMore: true,
      fields: [
        {
          question_title: {
            name: "question_title",
            hideLabel: true,
            label: "Question Title",
            isRequired: true,
            colWidth: "col-md-12",
            inputType: "text",
            type: "text",
          },
          options: {
            name: "options",
            label: "Options",
            isRequired: true,
            colWidth: "col-md-12",
            inputType: "array",
            type: "array",
            canAddMore: false,
            fields: [
              {
                option_title: {
                  name: "option_title",
                  hideLabel: true,
                  label: "Option Title",
                  isRequired: true,
                  colWidth: "col-md-8",
                  inputType: "text",
                  type: "text",
                },
                answer: {
                  name: "answer",
                  label: "Correct answer",
                  isRequired: true,
                  colWidth: "col-md-4",
                  inputType: "radio",
                  type: "radio",
                },
              },
              {
                option_title: {
                  name: "option_title",
                  label: "Option Title",
                  isRequired: true,
                  colWidth: "col-md-8",
                  inputType: "text",
                  type: "text",
                },
                answer: {
                  name: "answer",
                  label: "Correct answer",
                  isRequired: true,
                  colWidth: "col-md-4",
                  inputType: "radio",
                  type: "radio",
                },
              },
              {
                option_title: {
                  name: "option_title",
                  label: "Option Title",
                  isRequired: true,
                  colWidth: "col-md-8",
                  inputType: "text",
                  type: "text",
                },
                answer: {
                  name: "answer",
                  label: "Correct answer",
                  isRequired: true,
                  colWidth: "col-md-4",
                  inputType: "radio",
                  type: "radio",
                },
              },
              {
                option_title: {
                  name: "option_title",
                  label: "Option Title",
                  isRequired: true,
                  colWidth: "col-md-8",
                  inputType: "text",
                  type: "text",
                },
                answer: {
                  name: "answer",
                  label: "Correct answer",
                  isRequired: true,
                  colWidth: "col-md-4",
                  inputType: "radio",
                  type: "radio",
                },
              },
            ],
          },
        },
      ],
    },
  },

  addMoreFields: {
    question_title: "",
    options: [
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
    ],
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
