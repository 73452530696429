import { getPropByString } from "../../../utils/CommonFunction"

const ImagePreview = (props: any) => {

    return (
        <div>
            <img src={ "http://localhost:3005/store/" + getPropByString(props.values, props.name)} alt="" />
        </div>
    )
}

export default ImagePreview;