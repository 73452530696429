import AxiosInstance from "../../utils/AxiosInstance";
import { CategoryDetailInterface } from "./models/CategoryDetailInterface";

const API_ROUTE = "categorys";
export const getCategoryList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getCategoryDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createCategory = async (data: CategoryDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteCategory = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateCategory = async (index: string, data: CategoryDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, data);
};
