import { Route } from "react-router-dom";
import QuestionCreate from "./pages/QuestionCreate";
import QuestionList from "./pages/QuestionList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "question"; 
const QuestionRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<QuestionList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<QuestionCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<QuestionCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default QuestionRoute;