const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
    
  },

  formFields: {
    name: {
      label: "Name",
      isRequired: true,
      colWidth: "col-md-4",
      inputType: "text",
      type: "text",
    },

    email: {
      label: "Email",
      isRequired: true,
      colWidth: "col-md-4",
      inputType: "text",
      type: "email",
    },

    phone_no: {
      label: "phone",
      isRequired: true,
      colWidth: "col-md-4",
      inputType: "text",
      type: "text",
    },

    subject: {
      label: "Subject",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "text",
    },

    message: {
      label: "Message",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "text",
    },
  },
  

  addMoreFields: {
    question_title: "",
    options: [
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
    ],
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
