const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
  },

  formFields: {

    dept_id: {
      label: "Select Department",
      isRequired: false,
      colWidth: "col-md-4",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/departments",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Department",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },

    // course_id: {
    //   label: "Select course",
    //   isRequired: false,
    //   colWidth: "col-md-4",
    //   type: "select",
    //   inputType: "select",
    //   asynchLoad: true,
    //   dataUrl: "/courses",
    //   keyValue: "id",
    //   keylabel: "title",
    //   placeholder: "Select Course",
    //   options: [
    //     {
    //       label: "",
    //       value: "",
    //     },
    //   ],
    // },

    // category_id: {
    //   label: "Select Category",
    //   isRequired: false,
    //   colWidth: "col-md-4",
    //   type: "select",
    //   inputType: "select",
    //   asynchLoad: true,
    //   dataUrl: "/categorys",
    //   keyValue: "id",
    //   keylabel: "name",
    //   placeholder: "Select Category",
    //   options: [
    //     {
    //       label: "",
    //       value: "",
    //     },
    //   ],
    // },

    title: {
      label: "Title",
      isRequired: false,
      colWidth: "col-md-8",
      inputType: "text",
      type: "text",
    },

    description: {
      label: "Description",
      isRequired: false,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
    },

    publish_date: {
      label: "Publish Date",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "date",
      type: "date",
    },

    image: {
      label: "PDF",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    }
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
