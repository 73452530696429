const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    dept_id:"",
    exam_date:"",
    offer_pdf:"",
    about_us_pdf:"",
    live_lecture_pdf:"",
    strategy_pdf:"",
    success_story_pdf:"",
    offer: [
      {
        offer_title: "",
        offer_link: ""
      },
    ]
  },
  formFields: {

    dept_id: {
      label: "Select Department",
      isRequired: false,
      colWidth: "col-md-6",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/departments",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Department",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },
   
    exam_date: {
      label: "Exam Date",
      isRequired: false,
      colWidth: "col-md-6",
      type: "date",
    },
    clinic_pdf: {
      label: "Clinic pdf",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },
    offer_pdf: {
      label: "Offer pdf",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    about_us_pdf: {
      label: "About us pdf",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    live_lecture_pdf: {
      label: "Live Lecture pdf",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    strategy_pdf: {
      label: "Strategy pdf",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    success_story_pdf: {
      label: "Success Story pdf",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    offer: {
      label: "Offer",
      isRequired: false,
      colWidth: "col-md-12",
      inputType: "array",
      type: "array",
      canAddMore: true,
      fields: [
        {
          offer_title: {
            name: "offer_title",
            hideLabel: false,
            label: "Offer Title",
            isRequired: true,
            colWidth: "col-md-5",
            inputType: "text",
            type: "text",
          },
          offer_link: {
            name: "offer_link",
            hideLabel: false,
            label: "Offer Link",
            isRequired: true,
            colWidth: "col-md-7",
            inputType: "text",
            type: "text",
          }
        },
      ],
    },
  },

  addMoreFields: {
    offer_title: "",
    offer_link: "",
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
