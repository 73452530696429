import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { UserDetailInterface } from "./models/UserDetailInterface";
import {
  createUser,
  deleteUser,
  getUserDetail,
  getUserList,
  updateUser,
} from "./UserAPI";

export interface UserStateInterface {
  users: UserDetailInterface[];
  favourites: UserDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: UserDetailInterface;
}

const initialState: UserStateInterface = {
  users: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    email: "",
    status: "",
  },
};

export const getUserListAsync = createAsyncThunk(
  "user/list",
  async (data: any) => {
    const response = await getUserList(data);
    return response.data;
  }
);
export const getUserDetailAsync = createAsyncThunk(
  "user/detail",
  async (index: string) => {
    const response = await getUserDetail(index);
    return response.data;
  }
);

export const createUserAsync = createAsyncThunk(
  "user/create",
  async (data: UserDetailInterface) => {
    const response = await createUser(data);
    return response.data;
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/delete",
  async (index: string) => {
    const response = await deleteUser(index);
    return response.data;
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/update",
  async (data: { index: string; data: UserDetailInterface }) => {
    const response = await updateUser(data.index, data.data);
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getUserListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.users = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getUserListAsync.rejected, (state) => {
        state.status = "failed";
      })
     
      // GET DETAIL
      .addCase(getUserDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getUserDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      
      // CREATE
      .addCase(createUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createUserAsync.rejected, (state) => {
        state.status = "failed";
      })

      // UPDATE
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateUserAsync.rejected, (state) => {
        state.status = "failed";
      })

      // DELETE
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteUserAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = userSlice.actions;
export const UserState = (state: RootState) => state.user;
export default userSlice.reducer;
