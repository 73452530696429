import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { MyProfileDetailInterface } from "./models/MyProfileDetailInterface";
import {
  updateMyProfile,
} from "./MyProfileAPI";

export interface MyProfileStateInterface {
  myprofiles: MyProfileDetailInterface[];
  favourites: MyProfileDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: MyProfileDetailInterface;
}

const initialState: MyProfileStateInterface = {
  myprofiles: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
};


export const updateMyProfileAsync = createAsyncThunk(
  "myprofile/update",
  async (data: { data: MyProfileDetailInterface }) => {
    const response = await updateMyProfile(data.data);
    return response.data;
  }
);

export const myprofileSlice = createSlice({
  name: "myprofile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // UPDATE PROFILE
    .addCase(updateMyProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateMyProfileAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateMyProfileAsync.rejected, (state) => {
        state.status = "failed";
      })
     
  },
});

export const {} = myprofileSlice.actions;
export const MyProfileState = (state: RootState) => state.myprofile;
export default myprofileSlice.reducer;
