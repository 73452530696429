import { Route } from "react-router-dom";
import CourseCreate from "./pages/CourseCreate";
import CourseList from "./pages/CourseList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "course"; 
const CourseRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<CourseList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<CourseCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<CourseCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default CourseRoute;