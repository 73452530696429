import { Route } from "react-router-dom";
import TopicCreate from "./pages/TopicCreate";
import TopicList from "./pages/TopicList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "topic"; 
const TopicRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<TopicList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<TopicCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<TopicCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default TopicRoute;