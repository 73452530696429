import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { SubscriptionDetailInterface } from "./models/SubscriptionDetailInterface";
import {
  createSubscription,
  deleteSubscription,
  getSubscriptionDetail,
  getSubscriptionList,
  updateSubscription,
} from "./SubscriptionAPI";

export interface SubscriptionStateInterface {
  subscriptions: SubscriptionDetailInterface[];
  favourites: SubscriptionDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: SubscriptionDetailInterface;
}

const initialState: SubscriptionStateInterface = {
  subscriptions: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
};

export const getSubscriptionListAsync = createAsyncThunk(
  "subscription/list",
  async (data: any) => {
    const response = await getSubscriptionList(data);
    return response.data;
  }
);
export const getSubscriptionDetailAsync = createAsyncThunk(
  "subscription/detail",
  async (index: string) => {
    const response = await getSubscriptionDetail(index);
    return response.data.data;
  }
);

export const createSubscriptionAsync = createAsyncThunk(
  "subscription/create",
  async (data: SubscriptionDetailInterface) => {
    const response = await createSubscription(data);
    return response.data;
  }
);

export const deleteSubscriptionAsync = createAsyncThunk(
  "subscription/delete",
  async (index: string) => {
    const response = await deleteSubscription(index);
    return response.data;
  }
);

export const updateSubscriptionAsync = createAsyncThunk(
  "subscription/update",
  async (data: { index: string; data: SubscriptionDetailInterface }) => {
    const response = await updateSubscription(data.index, data.data);
    return response.data;
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getSubscriptionListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubscriptionListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.subscriptions = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getSubscriptionListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getSubscriptionDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubscriptionDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getSubscriptionDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createSubscriptionAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateSubscriptionAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteSubscriptionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSubscriptionAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteSubscriptionAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = subscriptionSlice.actions;
export const SubscriptionState = (state: RootState) => state.subscription;
export default subscriptionSlice.reducer;
