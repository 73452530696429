const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
    title: "",
  },

  formFields: {

    dept_id: {
      label: "Select Department",
      isRequired: true,
      colWidth: "col-md-6",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/departments",
      keyValue: "id",
      keylabel: "name",
      placeholder: "Select Department",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },

    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    price: {
      label: "Price",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },
    status: {
      label: "Select Status",
      isRequired: true,
      colWidth: "col-md-6",
      type: "select",
      inputType: "select",
      placeholder: "Select",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
