import { useEffect, useState } from "react";
import { Formik } from "formik";
import AxiosInstance from "../utils/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { getProfileAsync, setProfile } from "../stores/appSlice";
import { useAppDispatch } from "../stores/hooks";
import { store } from "../stores";

const Signin = () => {
  const navigate = useNavigate();
  const dispatchApp = useAppDispatch();
  const token = localStorage.getItem("token");
  const [loginSuccess, setLoginSuccess] = useState(token ? true : false);

  useEffect(() => {
    if (loginSuccess) {
      navigate("/");
    }
  }, [loginSuccess]);

  const onsubmit = async (values: any) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    const res = await AxiosInstance.post("signin", data);
    if (res?.data?.status === 200) {
      if(res?.data?.data?.user?.role === "Admin"){
        setLoginSuccess(true);
        localStorage.setItem("token", res?.data?.data?.token);
        dispatchApp(setProfile(res?.data?.data?.user));
        navigate("/");
        window.location.reload();
      }else{
        alert("You are not an administrator, Please try with another credentials.");
        navigate("/signin");
      }
    }else{
      alert("something went wrong");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          enableReinitialize={true}
          validate={(values: { email: string; password: string }) => {
            const errors: { email?: string; password?: string } = {};

            if (!values.email) {
              errors.email = "Required";
            }

            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          onSubmit={onsubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="card">
                <div className="card-body text-center">
                  <div className="mb-4">
                    {/* <img className="login-logo" src="" /> */}
                    <i className="feather icon-unlock auth-icon"></i>
                  </div>
                  <h3 className="mb-4">Login</h3>
                  <div className="input-group mb-3">
                    <input
                      className={
                        errors.email && touched.email && errors.email
                          ? "form-control error"
                          : "form-control"
                      }
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Email"
                      autoFocus={true}
                    />
                  </div>
                  <div className="input-group mb-4">
                    <input
                      className={
                        errors.password && touched.password && errors.password
                          ? "form-control error"
                          : "form-control"
                      }
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder="Password"
                    />
                  </div>
                  {/* <div className="form-group text-left">
                    <div className="checkbox checkbox-fill d-inline">
                      <input
                        type="checkbox"
                        name="checkbox-fill-1"
                        id="checkbox-fill-a1"
                      />
                      <label htmlFor="checkbox-fill-a1" className="cr">
                        {" "}
                        Save Details
                      </label>
                    </div>
                  </div> */}
                  <button
                    type="submit"
                    className="btn btn-primary shadow-2 mb-4 mt-4"
                  >
                    Login
                  </button>
                  {/* <p className="mb-2 text-muted">
                    Forgot password?{" "}
                    <a href="auth-reset-password.html">Reset</a>
                  </p> */}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Signin;
