const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
 

    course_id: {
      label: "Select Course",
      isRequired: false,
      colWidth: "col-md-6",
      type: "select",
      inputType: "select",
      asynchLoad: true,
      dataUrl: "/courses",
      keyValue: "id",
      keylabel: "title",
      placeholder: "Select Course",
      options: [
        {
          label: "",
          value: "",
        },
      ],
    },
    name: {
      label: "Category name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
      ],
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
