import { useState } from "react";

const Test = () => {
  const [fieldData, setFieldData] = useState({
    questions: [
      {
        question: "",
        options: [{ title: "" }],
      },
    ],
  });

  const addOptionBtn = (i) => {
    const questions = [...fieldData.questions];
    questions[i].options.push({ title: "" });
    setFieldData({ questions });
  };

  const removeOptionBtn = (i, idx) => {
    const questions = [...fieldData.questions];
    questions[i].options.splice(idx, 1);
    setFieldData({ questions });
  };

  const addQuestionBtn = () => {
    const questions = [...fieldData.questions];
    questions.push({
      question: "",
      options: [{ title: "" }],
    });
    setFieldData({ questions });
  };

  const removeQuestionBtn = (i) => {
    const questions = [...fieldData.questions];
    questions.splice(i, 1);
    setFieldData({ questions });
  };

  const handleOnChange = (index, e) => {
   
  };

  return (
    <>
      <div className="row">
        <div className="form-group mb-4">
          {fieldData.questions.map((item, index) => {
            return (
              <div className="row border p-3 mb-2 bg-warning">
                <div className="row mb-1">
                  <div className="col-11">
                    <input
                      name="question"
                      className="form-control"
                      placeholder="Question Title"
                      value={""}
                      onChange={(e) => {
                        handleOnChange(index, e);
                      }}
                    />
                  </div>
                  <div className="col-1">
                    <button
                      type="button"
                      onClick={() => {
                        removeQuestionBtn(index);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>

                {item?.options?.map((item, idx) => {
                  return (
                    <div className="col-md-3">
                      <div className="input-group">
                        <input
                          name="optionA"
                          className="form-control"
                          placeholder="Option A"
                          value={item.title}
                          onChange={(e) => {
                            handleOnChange(idx, e);
                          }}
                        />

                        <button
                          type="button"
                          onClick={() => {
                            addOptionBtn(index);
                          }}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            removeOptionBtn(index, idx);
                          }}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <button
        type="button"
        onClick={addQuestionBtn}
      >
        Add Question
      </button>
      <hr />
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </>
  );
};
export default Test;
