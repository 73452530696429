import { getPropByString } from "../../../utils/CommonFunction";

const Radio = (props: any) => {
  
  const OnChangeFunction = (e: any) => {
    var i = e?.target?.name?.split(".")[1];
    props.setFieldValue(`questions.${i}.options.0.answer`, 0);
    props.setFieldValue(`questions.${i}.options.1.answer`, 0);
    props.setFieldValue(`questions.${i}.options.2.answer`, 0);
    props.setFieldValue(`questions.${i}.options.3.answer`, 0);
    props.setFieldValue(props.name, e.target.checked ? 1 : 0);
  };

  return (
    <div className="form-check form-check-inline">
      <input
        id={props?.name}
        type={"radio"}
        name={props.name}
        onBlur={props?.handleBlur}
        onChange={(e) => {
          OnChangeFunction(e);
        }}
        placeholder={props?.fieldDetails?.label}
        value={getPropByString(props?.values, props?.name)}
        checked={
          getPropByString(props?.values, props?.name) == 1 ? true : false
        }
      />
    </div>
  );
};

export default Radio;
