import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDashboard, getProfile } from "./appAPI";

export interface AppStateInterface {
  profile: any;
  dashboard: any;
  isMobileMenuOpen: boolean;
  isUserLoggedIn: boolean;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
}

const initialState: AppStateInterface = {
  profile: {},
  dashboard : {},
  isMobileMenuOpen: false,
  isUserLoggedIn: false,
  status: "idle",
};

export const getProfileAsync = createAsyncThunk("/", async () => {
  const response = await getProfile();
  return response.data;
});

export const getDashboardAsync = createAsyncThunk("/dashboard", async () => {
  const response = await getDashboard();
  return response.data;
});

export const appSlice = createSlice({
  name: "app",
  initialState,

  reducers: {
    setProfile: (state, action: PayloadAction<[]>) => {
      state.profile = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder

      // get profile
      .addCase(getProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.profile = action.payload.data;
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.status = "failed";
      })

      // get Dashboard
      .addCase(getDashboardAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.dashboard = action.payload.data;
      })
      .addCase(getDashboardAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setProfile } = appSlice.actions;
export const appState = (state: any) => state.app;
export default appSlice.reducer;
