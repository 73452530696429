import { Route } from "react-router-dom";
import WhyusList from "./pages/WhyusList";
import WhyusCreate from "./pages/WhyusCreate";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "whyus";
const WhyusRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<WhyusList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<WhyusCreate />} key={`${MODULE_ROUTE}_create`} />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<WhyusCreate />} key={`${MODULE_ROUTE}_edit`} />,
];

export default WhyusRoute;