import AxiosInstance from "../../utils/AxiosInstance";
import { QuestionDetailInterface } from "./models/QuestionDetailInterface";

const API_ROUTE = "questions";
export const getQuestionList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getQuestionDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createQuestion = async (data: QuestionDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] == 'questions'){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteQuestion = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateQuestion = async (index: string, data: QuestionDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    if(keys[i] == 'questions'){
      formData.append(keys[i], JSON.stringify(data[keys[i]]));
    }else{
      formData.append(keys[i], data[keys[i]]);
    }
  }
  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, formData);
};
