import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { QuestionDetailInterface } from "./models/QuestionDetailInterface";
import {
  createQuestion,
  deleteQuestion,
  getQuestionDetail,
  getQuestionList,
  updateQuestion,
} from "./QuestionAPI";

export interface QuestionStateInterface {
  questions: QuestionDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: QuestionDetailInterface;
}

const initialState: QuestionStateInterface = {
  questions: [],
  totalRecord: 0,
  status: "idle",

  detail: {
    id: "",
    category_id: "",
    course_id: "",
    question_text: "",
    question_detail: "",
    publish_date: "",
    publish_time: "",
    status: "",
    questions:{}
    // questions: {
    //   question_title: "",
    //   options: {
    //     option_title: "",
    //     answer: false,
    //   },
    // },
  },
};

export const getQuestionListAsync = createAsyncThunk(
  "question/list",
  async (data: any) => {
    const response = await getQuestionList(data);
    return response?.data;
    // return response?.data?.data; //for Local
  }
);
export const getQuestionDetailAsync = createAsyncThunk(
  "question/detail",
  async (index: string) => {
    const response = await getQuestionDetail(index);
    return response?.data;
    // return response?.data?.data; //for Local
  }
);

export const createQuestionAsync = createAsyncThunk(
  "question/create",
  async (data: QuestionDetailInterface) => {
    const response = await createQuestion(data);
    return response?.data;
  }
);

export const deleteQuestionAsync = createAsyncThunk(
  "question/delete",
  async (index: string) => {
    const response = await deleteQuestion(index);
    return response?.data;
  }
);

export const updateQuestionAsync = createAsyncThunk(
  "question/update",
  async (data: { index: string; data: QuestionDetailInterface }) => {
    const response = await updateQuestion(data.index, data?.data);
    return response?.data;
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getQuestionListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQuestionListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.questions = action.payload?.data;
        state.totalRecord = action.payload?.total;
      })
      .addCase(getQuestionListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getQuestionDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQuestionDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getQuestionDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createQuestionAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createQuestionAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQuestionAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateQuestionAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteQuestionAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteQuestionAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = questionSlice.actions;
export const QuestionState = (state: RootState) => state.question;
export default questionSlice.reducer;
