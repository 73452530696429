import { Link, useLocation } from "react-router-dom";
import MenuItems from "./menu.json";

const SideBar = () => {
  const location = useLocation();
  const NO_SIDEBAR_MENU = ["/signin", "/signin/", "/signup", "/signup/"];
  
  return NO_SIDEBAR_MENU.indexOf(location.pathname) === -1 ? (
    <>
      <div className="loader-bg">
        <div className="loader-track">
          <div className="loader-fill"></div>
        </div>
      </div>

      <nav className="pcoded-navbar">
        <div className="navbar-wrapper">
          <div className="navbar-brand header-logo">
            <a href="/" className="b-brand">
              <div className="b-bg">
                <i className="feather icon-trending-up"></i>
              </div>
              <span className="b-title">STUDY BUDDY</span>
            </a>

            <a className="mobile-menu" id="mobile-collapse" href="#!">
              <span></span>
            </a>
          </div>

          <div className="navbar-content scroll-div">
            <ul className="nav pcoded-inner-navbar">
              <li className="nav-item pcoded-menu-caption">
                <label>Navigation</label>
              </li>

              {MenuItems.map((menuItem, index) => {
                const isActive = location.pathname.indexOf(menuItem.link) === 0;
                return (
                  <li
                    key={`menuItem_${index}`}
                    className={`${isActive ? "nav-item active" : "nav-item"} 
                  ${menuItem.visible ? "" : "d-none"} ${
                      location.pathname === "/" && index === 0 ? "active" : ""
                    } `}
                  >
                    <Link to={ menuItem.link } className="nav-link" id={`nav_link_${index}`} >
                      <span className="pcoded-micon">
                        <i className={menuItem.icon}></i>
                      </span>
                      <span className="pcoded-mtext">{menuItem.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  ) : null;
};

export default SideBar;
