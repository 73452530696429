import { Route } from "react-router-dom";
import MyProfileCreate from "./pages/MyProfileCreate";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "myprofile"; 
const MyProfileRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<MyProfileCreate />}  key={`${MODULE_ROUTE}_create`}  />,
  ];

export default MyProfileRoute;