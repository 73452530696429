const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
    name: {
      label: "Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    email: {
      label: "Email",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "email",
    },

    phone_no: {
      label: "Phone",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "number",
    },

    password: {
      label: "Password",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "password",
    },

    
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
