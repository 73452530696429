import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getOldQuestionListAsync, OldQuestionState } from "../OldQuestionSlice";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

/**
 * OldQuestioning list page
 */
const OldQuestionList = () => {
  const { oldquestions, status, totalRecord } =
    useAppSelector(OldQuestionState);

  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;


  useEffect(() => {
    getPageData();
  }, [dispatch, page]);

  const getPageData = () => {
    const filter = {
      page: page,
    };
    dispatch(getOldQuestionListAsync(filter));
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
   
    {
      dataField: "title",
      text: "Title",
      formatter: (cellContent: any, row: any) =>
        row?.title ? row?.title?.slice(0, 140)+'...' : ""
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            onClick={() => {
              navigate(`/oldquestion/update/${row?.id}`);
            }}
            className="feather icon-eye text-secondary fw-bold"
          ></i>
        </div>
      ),
    }
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Old Question</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Old Questions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Old Questions</h5>
                    </Col>
                    
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={oldquestions}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props?.searchProps} />
                              <ClearSearchButton
                                {...props?.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />
                              <ExportCSVButton
                                {...props?.csvProps}
                                className="btn-success fw-bold ms-2"
                              >
                                Export CSV
                              </ExportCSVButton>
                            </div>
                            <BootstrapTable
                              // filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props?.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>                    
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
export default OldQuestionList;
