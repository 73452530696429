import { Route } from "react-router-dom";
import DepartmentCreate from "./pages/DepartmentCreate";
import DepartmentList from "./pages/DepartmentList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "department"; 
const DepartmentRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<DepartmentList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<DepartmentCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<DepartmentCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default DepartmentRoute;