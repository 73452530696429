import { FieldArray } from "formik";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import FormField from ".";
import AxiosInstance from "../../../utils/AxiosInstance";
import { getPropByString } from "../../../utils/CommonFunction";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const ArrayType = (props: any) => {
  const { values, name, fieldDetails, addMoreFields } = props;
  const fieldValues = getPropByString(values, name);
  const params = useParams();
  const location = useLocation();

  const removeFeildArray = async (id: any) => {
    const res = await AxiosInstance.delete(
      location.pathname.split("/")[1] + "/delete/" + id
    );
    if (res.data.status === 200) {
      toast.success("Question deleted!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className="group-array">
          {fieldValues?.map((field, index) => {
            const keys = Object?.keys(field);
            return (
              <div key={`${name}${index}`} className="group-array-fields mb-3">
                <Row>
                  <ToastContainer />
                  <Col>
                    <Row>
                      {Object.keys(fieldDetails.fields[0])?.map((key, ind) => {
                        const fD = fieldDetails?.fields[0][key] || {};

                        // const fName = fD.inputType === "radio" ? `${name}.${key}` : `${name}.${index}.${key}`;

                        return (
                          <div
                            key={`field_${name}${index}_key_${key}`}
                            className={fD?.colWidth}
                          >
                            <FormField
                              name={`${name}.${index}.${key}`}
                              fieldDetails={fD}
                              values={values}
                              errors={props?.errors}
                              touched={props?.touched}
                              setFieldValue={props?.setFieldValue}
                              handleChange={props?.handleChange}
                              handleBlur={props?.handleBlur}
                              index={index}
                            />
                          </div>
                        );
                      })}
                    </Row>
                  </Col>
                  {fieldDetails?.canAddMore && fieldValues?.length > 1 ? (
                    <Col md={1}>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            )
                          ) {
                            if (params?.id) {
                              removeFeildArray(field?.id);
                            }
                            arrayHelpers?.remove(index);
                          }
                        }}
                      >
                        <i className="feather icon-minus-circle me-0"></i>
                      </button>
                    </Col>
                  ) : null}
                </Row>
              </div>
            );
          })}

          {fieldDetails?.canAddMore && (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                arrayHelpers.push(addMoreFields);
              }}
            >
              <i className="feather icon-plus-circle me-0"></i>
            </button>
          )}
        </div>
      )}
    />
  );
};

export default ArrayType;
