import { Route } from "react-router-dom";
import ContactUsCreate from "./pages/ContactUsCreate";
import ContactUsList from "./pages/ContactUsList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "contactus"; 
const ContactUsRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<ContactUsList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<ContactUsCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<ContactUsCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default ContactUsRoute;