import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createFAQ, deleteFAQ, getFAQDetail, getFAQList, updateFAQ } from "./FAQAPI";
import { FAQInterface } from "./models/FAQInterface";
import { RootState } from "../../stores";

export interface FaqStateInterface {
  faqs: FAQInterface[];
  favourites: FAQInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: FAQInterface;
}

const initialState: FaqStateInterface = {
  faqs: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    title: "",
    description: "",
  },
};

export const getFAQListAsync = createAsyncThunk(
  "faq/list",
  async (data: any) => {
    const response = await getFAQList(data);
    return response.data;
  }
);

export const getFAQDetailAsync = createAsyncThunk(
  "faq/detail",
  async (index: string) => {
    const response = await getFAQDetail(index);
    return response.data;
  }
);

export const createFAQAsync = createAsyncThunk(
  "faq/create",
  async (data: FAQInterface) => {
    const response = await createFAQ(data);
    return response.data;
  }
);

export const updateFAQAsync = createAsyncThunk(
  "faq/update",
  async (data: { index: string; data: FAQInterface }) => {
    const response = await updateFAQ(data.index, data.data);
    return response.data;
  }
);

export const deleteFAQAsync = createAsyncThunk(
  "faq/delete",
  async (index: string) => {
    const response = await deleteFAQ(index);
    return response.data;
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getFAQListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFAQListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.faqs = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getFAQListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getFAQDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFAQDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getFAQDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // // CREATE
      .addCase(createFAQAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFAQAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createFAQAsync.rejected, (state) => {
        state.status = "failed";
      })
      // // UPDATE
      .addCase(updateFAQAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFAQAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateFAQAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteFAQAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFAQAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteFAQAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { } = faqSlice.actions;
export const FAQState = (state: RootState) => state.faq;
export default faqSlice.reducer;