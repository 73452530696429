import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { OldQuestionDetailInterface } from "./models/OldQuestionDetailInterface";
import {
  createOldQuestion,
  deleteOldQuestion,
  getOldQuestionDetail,
  getOldQuestionList,
  updateOldQuestion,
} from "./OldQuestionAPI";

export interface OldQuestionStateInterface {
  oldquestions: OldQuestionDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: OldQuestionDetailInterface;
}

const initialState: OldQuestionStateInterface = {
  oldquestions: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    title: "",
  },
};

export const getOldQuestionListAsync = createAsyncThunk(
  "oldquestion/list",
  async (data: any) => {
    const response = await getOldQuestionList(data);
    return response.data.data;
  }
);
export const getOldQuestionDetailAsync = createAsyncThunk(
  "oldquestion/detail",
  async (index: string) => {
    const response = await getOldQuestionDetail(index);
    return response.data.data;
  }
);

export const createOldQuestionAsync = createAsyncThunk(
  "oldquestion/create",
  async (data: OldQuestionDetailInterface) => {
    const response = await createOldQuestion(data);
    return response.data;
  }
);

export const deleteOldQuestionAsync = createAsyncThunk(
  "oldquestion/delete",
  async (index: string) => {
    const response = await deleteOldQuestion(index);
    return response.data;
  }
);

export const updateOldQuestionAsync = createAsyncThunk(
  "oldquestion/update",
  async (data: { index: string; data: OldQuestionDetailInterface }) => {
    const response = await updateOldQuestion(data.index, data.data);
    return response.data;
  }
);

export const oldquestionSlice = createSlice({
  name: "oldquestion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getOldQuestionListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOldQuestionListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.oldquestions = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getOldQuestionListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getOldQuestionDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOldQuestionDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getOldQuestionDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createOldQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createOldQuestionAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createOldQuestionAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateOldQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOldQuestionAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateOldQuestionAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteOldQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteOldQuestionAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteOldQuestionAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = oldquestionSlice.actions;
export const OldQuestionState = (state: RootState) => state.oldquestion;
export default oldquestionSlice.reducer;
