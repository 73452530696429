import { Route } from "react-router-dom";
import CategoryCreate from "./pages/CategoryCreate";
import CategoryList from "./pages/CategoryList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "category"; 
const CategoryRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<CategoryList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<CategoryCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<CategoryCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default CategoryRoute;