import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import UserRoute from "../modules/user/UserRoute";
import CourseRoute from "../modules/course/CourseRoute";
import TopicRoute from "../modules/topic/TopicRoute";
import CategoryRoute from "../modules/category/CategoryRoute";
import QuestionRoute from "../modules/question/QuestionRoute";
import PushNotificationRoute from "../modules/pushnotification/PushNotificationRoute";
import SubscriptionRoute from "../modules/subscription/SubscriptionRoute";
import MyProfileRoute from "../modules/myprofile/MyProfileRoute";
import DepartmentRoute from "../modules/department/DepartmentRoute";
import SettingRoute from "../modules/setting/SettingRoute";
import Signin from "../modules/Signin";
import Signup from "../modules/Signup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appState } from "../stores/appSlice";
import Dashboard from "../modules/Dashboard";
import Test from "../modules/common/test";
import OldQuestionRoute from "../modules/oldquestion/OldQuestionRoute";
import ContactUsRoute from "../modules/contactus/ContactUsRoute";
import FAQRoute from "../modules/faq/FAQRoute";
import WhyusRoute from "../modules/whyus/WhyusRoute";
{ /* Import New Entry */ }

/**
 * This will return all routes of the application
 */
const AppRoute = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { isUserLoggedIn } = useSelector(appState);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      setToken(localStorage.getItem("token"));
    }
  }, [isUserLoggedIn]);

  const PrivateRoute = ({ children }: any) => {
    if (!token) {
      return <Navigate to="/signin" />;
    }
    return children;
  };

  return (
    <>
      <Routes>
        <Route path="/signin" element={<Signin />} />
      </Routes>

      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              {/* <PrivateRoute> */}
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/test" element={<Test />} />
                {UserRoute}
                {SubscriptionRoute}
                {CourseRoute}
                {TopicRoute}
                {CategoryRoute}
                {QuestionRoute}
                {PushNotificationRoute}
                {MyProfileRoute}
                {DepartmentRoute}
                {SettingRoute}
                {OldQuestionRoute}
                {ContactUsRoute}
                {FAQRoute}
                {WhyusRoute}
                {/* Add New Entry */}
              </Routes>
              {/* </PrivateRoute> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppRoute;
