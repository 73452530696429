const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
  },

  formFields: {
    name: {
      label: "Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select",
      options: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
      ],
    },
  },

  addMoreFields: {
    
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
