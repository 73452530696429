import { getPropByString } from "../../../utils/CommonFunction";

const Checkbox = (props: any) => {

  return (
    <div className="form-check form-check-inline">
       <input
        id={props?.name}
        name={props?.name}
        type={"checkbox"}
        readOnly={props?.readOnly}
        onBlur={props?.handleBlur}
        onChange={(e) => {
          props.setFieldValue(props.name, e.target.checked ? 1 : 0)
        }}
        placeholder={props?.fieldDetails?.label} 
        value={getPropByString(props?.values, props?.name) }
        checked={getPropByString(props?.values, props?.name) == 1 ? true : false}
        
        />
    </div>
  );
};

export default Checkbox;
